import React, { useEffect, createContext, useState } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./pages/Home";
import $axios from "./services/axios";
import { Modal } from "antd";

const ReachableContext = createContext(null);
const config = {
  title: "Token không hợp lệ!",
  maskClosable: false,
  footer: null,
  content: (
    <>
      <ReachableContext.Consumer>
        {(name) => `Có lỗi xảy ra vui lòng trở về trang chủ`}
      </ReachableContext.Consumer>
    </>
  ),
};

const App = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [token, setToken] = useState(localStorage.getItem("planeToken"));
  const history = useHistory();
  const location = useLocation();

  const decodeToken = async () => {
    // Lấy token từ URL
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (
        queryParams.get("verifyToken") ||
        localStorage.getItem("planeToken")
      ) {
        const verifyToken = queryParams.get("verifyToken");
        if (verifyToken) {
          const res = await $axios.$post("/login", {
            VerifyToken: verifyToken,
          });
          localStorage.setItem("planeToken", res.data.data.token);
          localStorage.setItem("planeUserInfo", JSON.stringify(res.data.data.userInfo));
          queryParams.delete("verifyToken");
          setToken(res.data.data.token);
          history.replace({
            pathname: location.pathname,
            search: queryParams.toString(),
          });
        }
      } else {
        modal.error(config);
      }
    } catch (error) {}
  };

  useEffect(() => {
    decodeToken();
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from="/" to={"/vemaybay"} />
        <Route
          exact
          path={"/vemaybay"}
          render={(props) => <Home {...props} token={token} />}
        />
        <Redirect to={"/vemaybay"} />
      </Switch>
      {contextHolder}
    </>
  );
};

export default App;
