import React, { useState, useEffect, createContext, useRef } from "react";
import BgForm from "../../images/bg-form.png";
import IconFlight from "../../images/ic-flights.svg";
import {
  Input,
  Button,
  Form,
  Radio,
  Select,
  Tag,
  Checkbox,
  notification,
  Row,
  Col,
  Modal,
  Collapse,
} from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import $axios from "../../services/axios";
import { checkTimeRange } from "../../helpers/utils";
import moment from "moment";
import _ from "lodash";
const ReachableContext = createContext(null);
const { Option } = Select;
const { TextArea } = Input;

const Step3 = ({
  onChangeStep,
  onChangeBookingInfo,
  airportInfo,
  chooseFlight,
  setLoading,
  isPromotionOrSameDayTicket,
  setStep,
  airports,
  airlines,
}) => {
  const [modal, contextHolder] = Modal.useModal();
const planeUserInfo = JSON.parse(localStorage.getItem("planeUserInfo"));
  const formRef = useRef(null);
  const config = {
    title: "Thông báo!",
    maskClosable: false,
    closable: false,
    footer: [
      <div className="text-center w-full">
        <Button
          key="ok"
          type="primary"
          onClick={() => (window.location.href = "/vemaybay")}
          className="bg-[#0064D2] text-white"
        >
          Về trang tìm kiếm
        </Button>
      </div>,
    ],
    content: (
      <>
        <ReachableContext.Consumer>
          {() => (
            <div className="text-[14px] text-[#0D1634] font-medium">
              Chuyến bay đã có sự thay đổi về giá. Vui lòng tìm kiếm lại chuyến
              bay để cập nhật!
            </div>
          )}
        </ReachableContext.Consumer>
        <br />
      </>
    ),
  };
  const [form] = Form.useForm();
  const invoice = Form.useWatch("invoice", form);
  const [baggages, setBaggages] = useState([]);
  const [baggagesOut, setBaggagesOut] = useState([]);
  const [passengerIdChoose, setPassengerIdChoose] = useState(null);
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucherCode, setVoucherCode] = useState(null);
  const [voucherTxt, setVoucherTxt] = useState("");
  const [processPriceDone, setProcessPriceDone] = useState(false);
  const [seatmaps, setSeatmaps] = useState([]);
  const FlightType = _.get(chooseFlight, "[0].FlightType");
  const Session = _.get(chooseFlight, "[0].Session");
  const AutoIssue = _.get(chooseFlight, "[0].AutoIssue");
  const currentYear = moment().year();
  const [showPriceDetail, setShowPriceDetail] = useState(false);
  const [totalListPrice, setTotalListPrice] = useState({
    totalFixed: 0,
    baggage: 0,
    baggage_out: 0,
    totalDisplay: 0,
    totalPriceAdd: 0,
    totalTax: 0,
    totalFareAdt: 0,
    totalFareChd: 0,
    totalFareInf: 0,
    totalVat: 0,
    totalFee: 0,
    totalOrigin: 0,
  });
  const minYearCHD = currentYear - 12;
  const maxYearCHD = currentYear - 2;
  const minYearINF = currentYear - 2;
  const maxYearINF = currentYear - 0;
  // const eligibleYear = currentYear - 13; // Năm cần chọn phải lớn hơn 12 tuổi
  // const startYear = 1940; // Năm bắt đầu
  // const yearsADT = Array.from({ length: eligibleYear - startYear + 1 }, (_, i) => startYear + i);
  const yearsCHD = Array.from(
    { length: maxYearCHD - minYearCHD + 1 },
    (_, i) => minYearCHD + i
  );
  const yearsINF = Array.from(
    { length: maxYearINF - minYearINF + 1 },
    (_, i) => minYearINF + i
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const days = Array.from({ length: 31 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );

  const ListPassengerAdt =
    _.get(chooseFlight, "[0].Adt", 0) > 0
      ? _.range(1, _.get(chooseFlight, "[0].Adt", 0) + 1).map((item) => {
          return {
            id: `adt-${item}`,
            FirstName: "",
            LastName: "",
            Type: "ADT", // loại khách ADT, CHD, INF
            IdCard: "",
            PassportNumber: "",
            Gender: "male",
            Membership: "",
            Birthday: "",
            ListBaggage: [],
            ListSeat: [],
          };
        })
      : [];

  const ListPassengerChd =
    _.get(chooseFlight, "[0].Chd", 0) > 0
      ? _.range(1, _.get(chooseFlight, "[0].Chd", 0) + 1).map((item) => {
          return {
            id: `chd-${item}`,
            FirstName: "",
            LastName: "",
            Type: "CHD", // loại khách ADT, CHD, INF
            IdCard: "",
            PassportNumber: "",
            Gender: "male",
            Membership: "",
            Birthday: "",
            ListBaggage: [],
            ListSeat: [],
          };
        })
      : [];

  const ListPassengerInf =
    _.get(chooseFlight, "[0].Inf", 0) > 0
      ? _.range(1, _.get(chooseFlight, "[0].Inf", 0) + 1).map((item) => {
          return {
            id: `inf-${item}`,
            FirstName: "",
            LastName: "",
            Type: "INF",
            IdCard: "",
            PassportNumber: "",
            Gender: "male",
            Membership: "",
            Birthday: "",
            ListBaggage: [],
            ListSeat: [],
          };
        })
      : [];

  const ListPassenger = _.concat(
    ListPassengerAdt,
    ListPassengerChd,
    ListPassengerInf
  ).map((item, index) => {
    item.Index = index + 1;
    return item;
  });

  const initialValues = _.reduce(
    ListPassenger,
    (acc, num) => {
      acc[`gender_${num.id}`] = "male";
      return acc;
    },
    { genderContact: "male", phone: planeUserInfo?.phone , email: planeUserInfo?.email, surnameContact: _.head(_.split(planeUserInfo?.fullName, ' ')), lastNameContact: _.join(_.tail(_.split(planeUserInfo?.fullName, ' ')), ' ') }
  );

  // công thức giá vé nét là giá vé + thuế  x số người
  // công thức giá vé tổng là giá vé nét + phí dịch vụ
  const verifyFlight = async (value) => {
    setLoading(true);
    try {
      const body = {
        ListFareData: _.map(chooseFlight, (f) => {
          return {
            Session: Session,
            FareDataId: f.FareDataId,
            ListFlight: f.ListFlight.map((f2) => {
              return {
                FlightValue: f2.FlightValue,
              };
            }),
          };
        }),
      };
      const res = await $axios.$post("/flights/verifyflight", body);
      if (res.data.data.ErrorCode === "000") {
        const totalNetPriceOrigin =
          _.get(chooseFlight, "[0].TotalNetPrice", 0) +
          _.get(chooseFlight, "[1].TotalNetPrice", 0);
        const totalNetPriceCompare =
          _.get(res.data.data, "ListFareStatus[0].FareData.TotalNetPrice", 0) +
          _.get(res.data.data, "ListFareStatus[1].FareData.TotalNetPrice", 0);
        return totalNetPriceOrigin === totalNetPriceCompare;
      } else {
        return false;
      }
    } catch (error) {
      // notification.error({
      //   status: "error",
      //   message: "Thất bại",
      //   description: "Đã có lỗi xảy ra!",
      // });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async (values) => {
    if (FlightType === "domestic") {
      const canBooking = await verifyFlight();
      //NOTE: check giá xem có được booking hay không
      if (!canBooking) {
        await modal.confirm(config);
        return;
      }
    }
    setLoading(true);
    try {
      const listFareDataInbound = _.map(chooseFlight, (f) => {
        return {
          Session: Session,
          FareDataId: f.FareDataId,
          TotalNetPrice: f.TotalNetPrice,
          ListFlight: f.ListFlight.map((f2) => {
            return {
              FlightValue: f2.FlightValue,
              FlightAmount: f.TotalPriceNew,
            };
          }),
        };
      });
      const listFareDataOutbound = _.map(chooseFlight, (f) => {
        return {
          Session: Session,
          FareDataId: f.FareDataId,
          TotalNetPrice: f.TotalNetPrice,
          AutoIssue: AutoIssue,
          ListFlight: f.ListFlight.map((f2) => {
            return {
              FlightValue: f2.FlightValue,
              FlightAmount: f.TotalPriceNew,
            };
          }),
        };
      });

      const _ListPassenger = ListPassenger.map((p) => {
        p.FirstName = values[`surname_${p.id}`];
        p.LastName = values[`lastName_${p.id}`];
        p.Gender = values[`gender_${p.id}`] === "male" ? true : false;
        const baggage = baggages.filter(
          (b) => b.Value === values[`baggage_${p.id}`]
        );
        const baggageOut = baggagesOut.filter(
          (b) => b.Value === values[`baggage_out_${p.id}`]
        );
        if (baggage.length > 0 || baggageOut.length > 0) {
          p.ListBaggage =
            chooseFlight.length === 1 ? baggage : _.concat(baggage, baggageOut);
        }
        // else {
        //   delete p.ListBaggage;
        // }
        // p.ListSeat = seatmaps.filter(
        //   (b) => b.Value === values[`seatmap_${p.id}`]
        // );
        if (p.Type !== "ADT") {
          p.Birthday = `${values[`day_${p.id}`]}${values[`month_${p.id}`]}${
            values[`year_${p.id}`]
          }`;
        }
        p.ListPrice = chooseFlight.map((f) => {
          console.log(f,'f');
          const StartPointName = (airports || []).find(
            (item) => item.code === f?.ListFlight[0]?.StartPoint || ""
          )?.city_name_vi;
          const EndPointName = (airports || []).find(
            (item) => item.code === f?.ListFlight[0]?.EndPoint || ""
          )?.city_name_vi;
          const Fare = p.Type === "ADT"
          ? f.FareAdt
          : p.Type === "CHD"
            ? f.FareChd
            : f.FareInf;
          const Tax = p.Type === "ADT"
            ? f.TaxAdt
            : p.Type === "CHD"
              ? f.TaxChd
              : f.TaxInf;
          const Fee = p.Type === "ADT"
          ? f.FeeAdt
          : p.Type === "CHD"
            ? f.FeeChd
            : f.FeeInf;
          const Vat = p.Type === "ADT"
          ? f.VatAdt
          : p.Type === "CHD"
            ? f.VatChd
            : f.VatInf;
          const Discount = p.Type === "ADT"
          ? f.DiscountAdt
          : p.Type === "CHD"
            ? f.DiscountChd
            : f.DiscountInf
          return {
            PaxType: p.Type,
            PaxNumb: 1,
            Fare: Fare,
            Tax: Tax,
            Fee: Fee,
            Vat: Vat,
            Discount: Discount,
            Total: Fare + Fee + Tax - Discount,
            Amount:
              p.Type === "ADT"
                ? f.FareAdtNew
                : p.Type === "CHD"
                  ? f.FareChdNew
                  : f.FareInfNew, // giá ipass
            StartPoint: f.ListFlight[0]?.StartPoint,
            StartPointName: StartPointName,
            EndPoint: f.ListFlight[0]?.EndPoint,
            EndPointName: EndPointName,
            AirlineName: (airlines || []).find(
              (item) => item.code === f?.ListFlight[0]?.Airline || ""
            )?.name,
            Airline: f.ListFlight[0]?.Airline,
            FlightNumber: f.ListFlight[0]?.FlightNumber,
            StartTime: f.ListFlight[0]?.StartDate,
            EndTime: f.ListFlight[0]?.EndDate,
            GroupClass: f.ListFlight[0]?.GroupClass,
            FareClass: f.ListFlight[0]?.FareClass,
            FareBasis: f.ListFlight[0]?.FareBasis,
            FlightType: f.FlightType,
          };
        });
        delete p.id;
        return p;
      });

      const body = {
        ContactFirstName: values.surnameContact,
        ContactLastName: values.lastNameContact,
        ContactGender: values.genderContact === "male" ? "true" : "false",
        ContactPhone: values.phone,
        ContactEmail: values.email,
        VoucherCode: voucherCode || "",
        Recovery: chooseFlight.length,
        Amount: totalListPrice.totalDisplay,
        AmountOriginal:
          totalListPrice.totalOrigin +
          totalListPrice.baggage +
          totalListPrice.baggage_out,
        AmountDiscount: voucherValue,
        ListPassenger: _ListPassenger,
        ListFareData:
          FlightType === "domestic"
            ? listFareDataInbound
            : listFareDataOutbound,
        PaymentType:2
      };

      if (invoice) {
        body.Invoice = {
          VatMst: values.taxCode,
          VatFullName: values.receiverReceive,
          VatEmail: values.email,
        };
      }
      console.log(body, "body");
      console.log(chooseFlight, "chooseFlight");
      //NOTE: Thanh toán trước gọi api booking sau
      if (isPromotionOrSameDayTicket) {
        try {
          setLoading(true);
          const res = await $axios.$post(
            "/payment/paymentRequiredTicketToday",
            body
          );
          if (res.data.statusCode === 200) {
            onChangeBookingInfo({
              ...body,
              totalListPrice,
              paymentUrl: res.data.data.paymentUrl,
              transaction: {},
              orderId: res.data.data.orderId,
              isPromotionOrSameDayTicket,
            });
            onChangeStep(4);
          } else {
            notification.error({
              status: "error",
              message: "Thất bại",
              description: "Đã có lỗi xảy ra!",
            });
          }
        } catch (error) {
          notification.error({
            status: "error",
            message: "Thất bại",
            description: error.response.data.message || "Đã có lỗi xảy ra!",
          });
        } finally {
          setLoading(false);
        }
      }
      //NOTE: Booking trước , thanh toán sau
      else {
        setLoading(true);
        try {
          const res = await $axios.$post("/payment/paymentRequired", body);
          if (res.data.statusCode === 200) {
            onChangeBookingInfo({
              ...body,
              totalListPrice,
              paymentUrl: res.data.data.paymentUrl,
              transaction: res.data.data.transaction,
              isPromotionOrSameDayTicket,
            });
            onChangeStep(4);
          } else {
            notification.error({
              status: "error",
              message: "Thất bại",
              description: "Đã có lỗi xảy ra!",
            });
          }
        } catch (error) {
          console.log(error, "error");
          notification.error({
            status: "error",
            message: "Thất bại",
            description: error.response.data.message || "Đã có lỗi xảy ra!",
          });
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBaggage();
    getPricesAddList();
    // const chooseFlightValid = handleCheckChooseFlight();
    // if (chooseFlightValid) {

    // } else {
    //   modal.confirm(config);
    // }
  }, []);

  useEffect(() => {
    if (passengerIdChoose) {
      handleChangeContactPersion();
    }
  }, [passengerIdChoose]);

  // const handleCheckChooseFlight = () => {
  //   let listPriceIn = _.get(chooseFlight, "[0].ListFlight[0].ListPrice", []);
  //   let listPriceOut = _.get(chooseFlight, "[1].ListFlight[0].ListPrice", []);
  //   return chooseFlight.length > 1 ? listPriceIn.length > 0 && listPriceOut.length > 0 : listPriceIn.length > 0;
  // };
  const handleInputChange = (e, field) => {
    const uppercaseValue = e.target.value
      .normalize("NFD") // Chuyển ký tự có dấu thành tổ hợp ký tự
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ các dấu
      .replace(/đ/g, "d") // Chuyển 'đ' thành 'd'
      .replace(/Đ/g, "D") // Chuyển 'Đ' thành 'D'
      .toUpperCase(); // Chuyển thành chữ in hoa;
    form.setFieldsValue({ [field]: uppercaseValue }); // Cập nhật giá trị trong form
  };

  // lấy list ghế
  const getSeatmap = async () => {
    setLoading(true);
    try {
      const res = await $axios.$post("/flights/getseatmap", {
        ListFareData: _.map(chooseFlight, (f) => {
          return {
            Session: f.Session,
            FareDataId: f.FareDataId,
            ListFlight: _.map(f.ListFlight, (f2) => {
              return {
                FlightValue: f2.FlightValue,
              };
            }),
          };
        }),
      });
      //  setSeatmaps(_.get(res, "data.ListBaggage"));
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  // lấy hành lý
  const getBaggage = async () => {
    setLoading(true);
    try {
      const res = await $axios.$post("/flights/getbaggage", {
        ListFareData: [
          {
            Session: chooseFlight[0].Session,
            FareDataId: chooseFlight[0].FareDataId,
            ListFlight: _.map(chooseFlight[0].ListFlight, (f) => {
              return {
                FlightValue: f.FlightValue,
              };
            }),
          },
        ],
      });
      setBaggages(_.get(res, "data.data.ListBaggage"));
      if (chooseFlight.length === 2) {
        const res = await $axios.$post("/flights/getbaggage", {
          ListFareData: [
            {
              Session: chooseFlight[1].Session,
              FareDataId: chooseFlight[1].FareDataId,
              ListFlight: _.map(chooseFlight[1].ListFlight, (f) => {
                return {
                  FlightValue: f.FlightValue,
                };
              }),
            },
          ],
        });
        setBaggagesOut(_.get(res, "data.data.ListBaggage"));
      }
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const getPricesAddList = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get("/payment/getAddPrice");
      calculatePriceAdd(res.data.data.data);
    } catch (error) {
      calculatePriceAdd([]);
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const calculatePriceAdd = (priceAdded) => {
    const _priceAdded = _.find(
      priceAdded,
      (v) => v.code === checkTimeRange(chooseFlight[0].ListFlight[0].StartDate)
    );
    chooseFlight = chooseFlight.map((f) => {
      let totalPriceAdd = 0;
      if (_priceAdded && _priceAdded.amount) {
        totalPriceAdd = _.sum([f.Adt, f.Chd, f.Inf]) * _priceAdded.amount;
      }
      const fareAdtNew =
        _priceAdded && f.Adt > 0 ? f.FareAdt + _priceAdded.amount : f.FareAdt;
      const fareChdNew =
        _priceAdded && f.Chd > 0 ? f.FareChd + _priceAdded.amount : f.FareChd;
      const fareInfNew =
        _priceAdded && f.Inf > 0 ? f.FareInf + _priceAdded.amount : f.FareInf;
      f.FareAdtNew = fareAdtNew; // giá vé tăng 1 ng
      f.FareChdNew = fareChdNew; // giá vé tăng 1 ng
      f.FareInfNew = fareInfNew; // giá vé tăng 1 ng
      f.TotalFareAdtNew = fareAdtNew * f.Adt;
      f.TotalFareChdNew = fareChdNew * f.Chd;
      f.TotalFareInfNew = fareInfNew * f.Inf;
      f.TotalNetPriceNew = _priceAdded
        ? f.TotalNetPrice + totalPriceAdd
        : f.TotalNetPrice;
      f.TotalPriceNew = _priceAdded
        ? f.TotalPrice + totalPriceAdd
        : f.TotalPrice;
      f.TotalTax = _.sum([
        f.TaxAdt * f.Adt,
        f.TaxChd * f.Chd,
        f.TaxInf * f.Inf,
      ]);
      f.TotalVat = _.sum([f.VatAdt, f.VatChd, f.VatInf]);
      f.TotalPriceFixed = _priceAdded
        ? f.TotalPrice + totalPriceAdd
        : f.TotalPrice;
      f.TotalPriceAdd = totalPriceAdd;
      return f;
    });
    setProcessPriceDone(true);
    setTotalListPrice({
      ...totalListPrice,
      totalFixed: _.sum(_.map(chooseFlight, (f) => f.TotalPriceNew)),
      totalDisplay: _.sum(_.map(chooseFlight, (f) => f.TotalPriceNew)),
      totalPriceAdd: _.sum(_.map(chooseFlight, (f) => f.TotalPriceAdd)),
      totalTax: _.sum(_.map(chooseFlight, (f) => f.TotalTax)),
      totalFareAdt: _.sum(_.map(chooseFlight, (f) => f.TotalFareAdtNew)),
      totalFareChd: _.sum(_.map(chooseFlight, (f) => f.TotalFareChdNew)),
      totalFareInf: _.sum(_.map(chooseFlight, (f) => f.TotalFareInfNew)),
      totalFee: _.sum(_.map(chooseFlight, (f) => f.TotalServiceFee)),
      totalVat: _.sum(_.map(chooseFlight, (f) => f.TotalVat)),
      totalOrigin: _.sum(_.map(chooseFlight, (f) => f.TotalPrice)),
    });
  };

  const applyVoucher = async (e) => {
    if (e) {
      setLoading(true);
      try {
        const res = await $axios.$get(`/voucher/getVoucher?voucherCode=${e}`);
        if (res.data.data.status === 1) {
          setVoucherValue(res.data.data.amount);
          setVoucherCode(e);
          setVoucherTxt(
            `<div class="text-[14px] ">Áp dụng mã giảm giá thành công! <span class="text-green-600 text-[16px]">
        ${res.data.data.amount.toLocaleString("vi-VN")} đ</span></div>`
          );
          setTotalListPrice({
            ...totalListPrice,
            totalDisplay:
              totalListPrice.totalFixed +
              totalListPrice.baggage +
              totalListPrice.baggage_out -
              res.data.data.amount,
          });
        } else {
          setVoucherTxt(
            `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
          );
          setVoucherValue(0);
          setVoucherCode("");
          setTotalListPrice({
            ...totalListPrice,
            totalDisplay:
              totalListPrice.totalFixed +
              totalListPrice.baggage +
              totalListPrice.baggage_out,
          });
        }
      } catch (error) {
        setVoucherTxt(
          `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
        );
        setVoucherValue(0);
        setVoucherCode("");
        setTotalListPrice({
          ...totalListPrice,
          totalDisplay:
            totalListPrice.totalFixed +
            totalListPrice.baggage +
            totalListPrice.baggage_out,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setVoucherTxt(
        `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
      );
      setVoucherValue(0);
      setVoucherCode("");
      setTotalListPrice({
        ...totalListPrice,
        totalDisplay:
          totalListPrice.totalFixed +
          totalListPrice.baggage +
          totalListPrice.baggage_out,
      });
    }
  };

  const handleChangeContactPersion = () => {
    form.setFieldsValue({
      surnameContact: form.getFieldValue(`surname_${passengerIdChoose}`),
      lastNameContact: form.getFieldValue(`lastName_${passengerIdChoose}`),
      genderContact: form.getFieldValue(`gender_${passengerIdChoose}`),
    });
  };

  const handleValuesFormChange = (changedValues, allValues) => {
    let totalBaggage = 0;
    let totalBaggageOut = 0;
    let totalBaggageIn = 0;
    Object.keys(allValues).forEach((key) => {
      if (key.includes("baggage_")) {
        totalBaggage += _.get(
          _.find(baggages, (b) => b.Value === allValues[key]),
          "Price",
          0
        );
      }
      if (key.includes("baggage_out_")) {
        totalBaggageOut += _.get(
          _.find(baggages, (b) => b.Value === allValues[key]),
          "Price",
          0
        );
      }
    });
    totalBaggageIn = totalBaggage - totalBaggageOut;
    setTotalListPrice({
      ...totalListPrice,
      baggage: totalBaggageIn,
      baggage_out: totalBaggageOut,
      totalDisplay:
        totalListPrice.totalFixed + totalBaggageIn + totalBaggageOut,
    });
  };

  return (
    <div className="bg-[#F6F6F6] mb-4">
      <div className="bg-[#3691e2] h-[300px] relative">
        <section className="step-indicator">
          <div className="step step1 actived">
            <div className="step-icon" onClick={() => onChangeStep(1)}>
              1
            </div>
            <p>Tìm kiếm</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step2 actived">
            <div className="step-icon">2</div>
            <p>Chọn vé</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step3 active">
            <div className="step-icon">3</div>
            <p>Thông tin</p>
          </div>
          <div className="indicator-line"></div>
          <div className="step step4">
            <div className="step-icon">4</div>
            <p>Thanh Toán</p>
          </div>
        </section>
        <img src={BgForm} alt="" className="w-full object-cover mx-auto" />
        <div className="flex w-full absolute top-[135px] left-1/2 transform -translate-x-1/2 justify-center gap-8">
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight?.[0]?.ListFlight?.[0]?.StartPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].StartPoint
                )?.city_name_vi
              }
            </div>
          </div>
          <div className="relative">
            <img src={IconFlight} alt="" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight[0]?.ListFlight?.[0]?.EndPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].EndPoint
                )?.city_name_vi
              }
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto absolute top-[200px] left-1/2 transform -translate-x-1/2 pb-[80px] flex flex-col">
          <Form
            ref={formRef}
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={(values) => {
              notification.error({
                message: "Thông báo",
                description: "Vui lòng nhập đầy đủ thông tin",
              });
            }}
            autoComplete="off"
            className="formCreateUser"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={initialValues}
            onValuesChange={handleValuesFormChange}
          >
            <div
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className="rounded-[20px] bg-white p-4 mb-[24px] text-[#0D1634]"
            >
              <div className="text-[20px] font-medium mb-1">
                Thông tin hành khách
              </div>
              <div className="text-[#D72F20] mb-3">(*) là bắt buộc</div>
              {ListPassenger.map((i, index) => (
                <div key={index}>
                  <Form.Item className="mb-2">
                    <div className="flex justify-between">
                      <div className="text-[14px] flex justify-between">
                        {index + 1}.{" "}
                        {i.Type === "ADT"
                          ? "Người lớn"
                          : i.Type === "CHD"
                            ? "Trẻ em"
                            : "Em bé"}
                      </div>
                      {i.Type === "ADT" && (
                        <Checkbox
                          checked={i.id === passengerIdChoose}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setPassengerIdChoose(i.id);
                            } else {
                              setPassengerIdChoose(null);
                            }
                          }}
                        >
                          Là người liên hệ
                        </Checkbox>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item name={`gender_${i.id}`} className="mb-3">
                    <Radio.Group>
                      <Radio value="male" className="text-[14px] font-medium">
                        {i.Type === "ADT" ? "Ông" : "Trai"}
                      </Radio>
                      <Radio value="female" className="text-[14px] font-medium">
                        {i.Type === "ADT" ? "Bà" : "Gái"}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Row gutter={8} className="mb-3">
                    <Col span={12}>
                      <Form.Item
                        name={`surname_${i.id}`}
                        label={
                          <div className="text-[14px] font-medium">Họ</div>
                        }
                        rules={[
                          { required: true, message: "Họ không được để trống" },
                        ]}
                        className="mb-0"
                      >
                        <Input
                          placeholder="NGUYEN"
                          onChange={(e) => {
                            handleInputChange(e, `surname_${i.id}`);
                            handleChangeContactPersion();
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <div className="text-[14px] font-medium">
                            Tên đệm & Tên
                          </div>
                        }
                        name={`lastName_${i.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Tên đệm & tên không được để trống",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Input
                          placeholder="VAN A"
                          onChange={(e) => {
                            handleInputChange(e, `lastName_${i.id}`);
                            handleChangeContactPersion();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {i.Type !== "ADT" && (
                    <Row gutter={8} className="mb-3">
                      <Col span={8}>
                        <Form.Item
                          name={`day_${i.id}`}
                          rules={[
                            { required: true, message: "Vui lòng chọn ngày" },
                          ]}
                          className="mb-0"
                        >
                          <Select placeholder="Ngày">
                            {days.map((day) => (
                              <Select.Option key={day} value={day}>
                                {day}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={`month_${i.id}`}
                          rules={[
                            { required: true, message: "Vui lòng chọn tháng" },
                          ]}
                          className="mb-0"
                        >
                          <Select placeholder="Tháng">
                            {months.map((month) => (
                              <Select.Option key={month} value={month}>
                                {month}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={`year_${i.id}`}
                          rules={[
                            { required: true, message: "Vui lòng chọn năm" },
                          ]}
                          className="mb-0"
                        >
                          <Select placeholder="Năm">
                            {(i.Type === "CHD" ? yearsCHD : yearsINF).map(
                              (year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {chooseFlight.length === 1 ? (
                    <>
                      <Form.Item
                        label="+ Hành lý ký gửi"
                        name={`baggage_${i.id}`}
                        className="mb-3"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Chọn hành lý ký gửi"
                        >
                          <Option key={-1} value={-1}>
                            Chọn hành lý ký gửi
                          </Option>
                          {baggages.map((item) => (
                            <Option key={item.Value} value={item.Value}>
                              {item.Name} ({item.Price.toLocaleString("vi-VN")}
                              ₫)
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {chooseFlight[0].ListFlight[0].ListSegment[0]
                        .HandBaggage && (
                        <Tag
                          color="#4480E7"
                          className="text-[14px] font-semibold rounded-[20px] px-2 py-1 mb-3 mr-0"
                        >
                          Đã bao gồm{" "}
                          {
                            chooseFlight[0].ListFlight[0].ListSegment[0]
                              .HandBaggage
                          }{" "}
                          xách tay
                        </Tag>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Item
                        label="+ Hành lý ký gửi chiều đi"
                        name={`baggage_${i.id}`}
                        className="mb-3"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Chọn hành lý ký gửi"
                        >
                          <Option key={-1} value={-1}>
                            Chọn hành lý ký gửi
                          </Option>
                          {baggages.map((item) => (
                            <Option key={item.Value} value={item.Value}>
                              {item.Name} ({item.Price.toLocaleString("vi-VN")}
                              ₫)
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {chooseFlight[0].ListFlight[0].ListSegment[0]
                        .HandBaggage && (
                        <Tag
                          color="#4480E7"
                          className="text-[14px] font-semibold rounded-[20px] px-2 py-1 mb-3 mr-0"
                        >
                          Đã bao gồm{" "}
                          {
                            chooseFlight[0].ListFlight[0].ListSegment[0]
                              .HandBaggage
                          }{" "}
                          xách tay
                        </Tag>
                      )}
                      <Form.Item
                        label="+ Hành lý ký gửi chiều về"
                        name={`baggage_out_${i.id}`}
                        className="mb-3"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Chọn hành lý ký gửi"
                        >
                          <Option key={-1} value={-1}>
                            Chọn hành lý ký gửi
                          </Option>
                          {baggagesOut.map((item) => (
                            <Option key={item.Value} value={item.Value}>
                              {item.Name} ({item.Price.toLocaleString("vi-VN")}
                              ₫)
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {chooseFlight[1].ListFlight[0].ListSegment[0]
                        .HandBaggage && (
                        <Tag
                          color="#4480E7"
                          className="text-[14px] font-semibold rounded-[20px] px-2 py-1 mb-3 mr-0"
                        >
                          Đã bao gồm{" "}
                          {
                            chooseFlight[1].ListFlight[0].ListSegment[0]
                              .HandBaggage
                          }{" "}
                          xách tay
                        </Tag>
                      )}
                    </>
                  )}

                  {/* <Form.Item label="Chọn ghế" name={`seatmap_${i}`} className="mb-3">
                    <Select style={{ width: "100%" }} placeholder="Chọn ghế">
                      {seatmaps.map((item) => (
                        <Option key={item.Value} value={item.Value}>
                          {item.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </div>
              ))}
              <div className="text-[#0D1634] text-[20px] mb-1">
                Thông tin liên hệ
              </div>
              <div className="text-[#D72F20] text-[14px] mb-3">
                (*) là bắt buộc
              </div>
              <Form.Item name="genderContact" className="mb-3">
                <Radio.Group>
                  <Radio value="male" className="text-[14px] font-medium">
                    Ông
                  </Radio>
                  <Radio value="female" className="text-[14px] font-medium">
                    Bà
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Row gutter={8} className="mb-3">
                <Col span={12}>
                  <Form.Item
                    name="surnameContact"
                    label={<div className="text-[14px] font-medium">Họ</div>}
                    rules={[
                      { required: true, message: "Họ không được để trống" },
                    ]}
                    className="mb-3"
                  >
                    <Input
                      placeholder="NGUYEN"
                      onChange={(e) => handleInputChange(e, "surnameContact")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <div className="text-[14px] font-medium">
                        Tên đệm & Tên
                      </div>
                    }
                    name="lastNameContact"
                    rules={[
                      {
                        required: true,
                        message: "Tên đệm & Tên không được để trống",
                      },
                    ]}
                    className="mb-3"
                  >
                    <Input
                      placeholder="VAN A"
                      onChange={(e) => handleInputChange(e, "lastNameContact")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="mb-3">
                <Col span={12}>
                  <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Số điện thoại không được để trống",
                      },
                      {
                        pattern: /^(03|05|07|08|09)\d{8}$|^\+84\d{9}$/,
                        message: "Số điện thoại không hợp lệ",
                      },
                    ]}
                    className="mb-3"
                  >
                    <Input placeholder="Nhập số điện thoại..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Email không được để trống",
                      },
                      {
                        type: "email",
                        message: "Vui lòng nhập email đúng định dạng",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập Email..." />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Ghi chú đơn hàng" name="note" className="mb-3">
                <TextArea placeholder="Ghi chú đơn hàng..." rows={2} />
              </Form.Item>
              <Form.Item
                label=""
                name="invoice"
                className="mb-3"
                valuePropName="checked"
              >
                <Checkbox>Xuất hoá đơn</Checkbox>
              </Form.Item>
              <div className="text-[14px] text-[#D72F20] mb-3">
                Quý khách chỉ có thể yêu cầu xuất hoá đơn trong vòng 5 ngày kể
                từ ngày xuất vé
              </div>
              {invoice && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="taxCode"
                      className="mb-3"
                      label={
                        <div className="text-[14px]">Mã số thuế (VAT)</div>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Mã số thuế không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Mã số thuế..." />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div className="text-[14px]">Người nhận hoá đơn</div>
                      }
                      name="receiverReceive"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Người nhận hoá đơn không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập người nhận hoá đơn..." />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
            <div
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className=" bg-white p-4 text-[#0D1634] rounded-[20px] mb-3"
            >
              <Form.Item name="voucher" className="w-full mb-0">
                <Input.Search
                  placeholder="Nhập mã giảm giá"
                  enterButton={
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#0064D2",
                        borderColor: "#0064D2",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                      }}
                    >
                      Áp dụng
                    </Button>
                  }
                  allowClear
                  onChange={(e) => {
                    if (!e.target.value) {
                      setVoucherTxt("");
                      setVoucherValue(0);
                    }
                  }}
                  onSearch={applyVoucher}
                />
              </Form.Item>
              {voucherTxt && (
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{ __html: voucherTxt }}
                />
              )}
            </div>
          </Form>
          <Collapse
            bordered={false}
            expandIcon={null}
            defaultActiveKey={["1"]}
            className="bg-white"
            onChange={(e) => {
              setShowPriceDetail(e.length > 0);
            }}
            items={[
              {
                key: "1",
                showArrow: false,
                label: (
                  <>
                    {processPriceDone && (
                      <div className="flex justify-between items-center">
                        <span className="text-[14px] font-medium mb-[12px]">
                          Tổng cộng (Thuế + Phí):
                        </span>
                        <div className="flex gap-1">
                          <span className="text-[16px] font-bold text-[#D72F20]">
                            {totalListPrice.totalDisplay.toLocaleString(
                              "vi-VN"
                            )}{" "}
                            ₫
                          </span>
                            {showPriceDetail ? (
                              <CaretDownOutlined
                                style={{
                                  fontSize: 14,
                                }}
                              />
                            ) : (
                              <CaretUpOutlined
                                style={{
                                  fontSize: 14,
                                }}
                              />
                            )}
                        </div>
                      </div>
                    )}
                  </>
                ),
                children: (
                  <>
                    {processPriceDone && (
                      <>
                        {chooseFlight.length === 2 && (
                          <div className="text-[12px] mb-2 font-medium">
                            Chiều đi
                          </div>
                        )}
                        <ul className="list-none">
                          {["Adt", "Chd", "Inf"].map((type) => (
                            <>
                              {chooseFlight[0][type] > 0 && (
                                <li
                                  key={type}
                                  className="flex justify-between mb-2 text-[12px] text-[#5F5F67]"
                                >
                                  <span className="w-2/6">
                                    {type === "Adt"
                                      ? "Vé người lớn"
                                      : type === "Chd"
                                        ? "Vé trẻ em"
                                        : "Vé em bé"}
                                    :
                                  </span>
                                  <span className="w-2/6 text-right">
                                    {chooseFlight[0][type]} x{" "}
                                    {chooseFlight[0][
                                      `Fare${[type]}New`
                                    ].toLocaleString("vi-VN")}
                                    đ
                                  </span>
                                  <span className="w-2/6 text-right">
                                    ={" "}
                                    {chooseFlight[0][
                                      `TotalFare${type}New`
                                    ].toLocaleString("vi-VN")}{" "}
                                    đ
                                  </span>
                                </li>
                              )}
                            </>
                          ))}
                          {chooseFlight[0].TotalTax > 0 && (
                            <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                              <span className="w-2/6">Thuế</span>
                              <span className="w-2/6 text-right"></span>
                              <span className="w-2/6 text-right">
                                ={" "}
                                {chooseFlight[0].TotalTax.toLocaleString(
                                  "vi-VN"
                                )}{" "}
                                đ
                              </span>
                            </li>
                          )}
                          {chooseFlight[0].TotalServiceFee > 0 && (
                            <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                              <span className="w-2/6">Phí dịch vụ</span>
                              <span className="w-2/6 text-right"></span>
                              <span className="w-2/6 text-right">
                                ={" "}
                                {chooseFlight[0].TotalServiceFee.toLocaleString(
                                  "vi-VN"
                                )}{" "}
                                đ
                              </span>
                            </li>
                          )}
                          {totalListPrice.baggage > 0 && (
                            <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                              <span className="w-2/6">Mua hành lý:</span>
                              <span className="w-2/6 text-right"></span>
                              <span className="w-2/6 text-right">
                                {" "}
                                {totalListPrice.baggage.toLocaleString("vi-VN")}
                                đ
                              </span>
                            </li>
                          )}
                        </ul>
                        {chooseFlight.length === 2 && (
                          <>
                            <div className="text-[12px] mb-2 font-medium">
                              Chiều về
                            </div>
                            <ul className="list-none">
                              {["Adt", "Chd", "Inf"].map((type) => (
                                <>
                                  {chooseFlight[1][type] > 0 && (
                                    <li
                                      key={type}
                                      className="flex justify-between mb-2 text-[12px] text-[#5F5F67]"
                                    >
                                      <span className="w-2/6">
                                        {chooseFlight[1][type] > 0 &&
                                          (type === "Adt"
                                            ? "Vé người lớn"
                                            : type === "Chd"
                                              ? "Vé trẻ em"
                                              : "Vé em bé")}
                                        :
                                      </span>
                                      <span className="w-2/6 text-right">
                                        {chooseFlight[1][type]} x{" "}
                                        {chooseFlight[1][
                                          `Fare${[type]}New`
                                        ].toLocaleString("vi-VN")}
                                        đ
                                      </span>
                                      <span className="w-2/6 text-right">
                                        ={" "}
                                        {chooseFlight[1][
                                          `TotalFare${type}New`
                                        ].toLocaleString("vi-VN")}{" "}
                                        đ
                                      </span>
                                    </li>
                                  )}
                                </>
                              ))}
                              {chooseFlight[1].TotalTax > 0 && (
                                <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                                  <span className="w-2/6">Thuế</span>
                                  <span className="w-2/6 text-right"></span>
                                  <span className="w-2/6 text-right">
                                    ={" "}
                                    {chooseFlight[1].TotalTax.toLocaleString(
                                      "vi-VN"
                                    )}{" "}
                                    đ
                                  </span>
                                </li>
                              )}
                              {chooseFlight[1].TotalServiceFee > 0 && (
                                <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                                  <span className="w-2/6">Phí dịch vụ</span>
                                  <span className="w-2/6 text-right"></span>
                                  <span className="w-2/6 text-right">
                                    ={" "}
                                    {chooseFlight[1].TotalServiceFee.toLocaleString(
                                      "vi-VN"
                                    )}{" "}
                                    đ
                                  </span>
                                </li>
                              )}
                              {totalListPrice.baggage_out > 0 && (
                                <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                                  <span className="w-2/6">Mua hành lý:</span>
                                  <span className="w-2/6 text-right"></span>
                                  <span className="w-2/6 text-right">
                                    {totalListPrice.baggage_out.toLocaleString(
                                      "vi-VN"
                                    )}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div className="fixed bottom-0 w-full">
        <div className="flex justify-between bg-white p-4 w-full">
          <Button
            onClick={() => {
              setStep((step) => step - 1);
            }}
            className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#0064D2] text-[15px] border-[#0064D2]"
          >
            <span className="font-semibold">Quay lại</span>
          </Button>
          <Button
            type="primary"
            onClick={() => {
              formRef.current.submit();
            }}
            className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-[#0064D2] text-white text-[15px]"
          >
            <span className="font-semibold">
              {isPromotionOrSameDayTicket ? "Thanh toán" : "Tiếp tục"}
            </span>
          </Button>
        </div>
        {/* <hr />
        <div className="flex bg-white h-[81px] w-full px-[24px] py-3 justify-between items-center">
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
            <a href="/ve-may-bay" className="text-[#22313F] text-[14px] font-medium">
              Vé máy bay
            </a>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Khách sạn</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé tàu</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé xe</span>
          </div>
        </div> */}
      </div>

      {contextHolder}
    </div>
  );
};

export default Step3;
